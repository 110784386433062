/* You can add global styles to this file, and also import other style files */

// COLOR VARIABLES
:root {
    --ttitle: #1E2E42;
    --tbody: #283E59;
    --tsubtitle: #283E5980;
    --tdropdown: #40556dd2;
    --twhite: #FFFFFF;
    --twhitepanel: #f8f8f881;
    --tblue: #477BBA;
    --tbuttonblue: #477BBA;
    --tdarkblue: #516C8E;
    --tbluelight: #477bba7b;
    --tbluebacklit: #4e9eff12;
    --tbluebacklitfocus: #4080cf21;
    --tgray: #283E5933;
    --telement: #CBD4E166;
    --telementhover: #cbd4e1a8;
    --tsection: #F8F8F8;
    --tfocus: #355b89;
    --tgreen: #87D978;
    --tyellow: #E5A50B;
    --tred: #CB4B4D;
    --tbuttonred: #CB4B4D;
}


[data-theme="dark"] {
    --ttitle: #e5e3e3;
    --tbody: #c6c6c6;
    --tsubtitle: #E8E8E880;
    --tdropdown: #f8f8f8e6;
    --twhite: #F8F8F8;
    --twhitepanel: #4e9eff2b;
    --tblue: #4b85cc;
    --tbuttonblue: #427ec8d6;
    --tdarkblue: #90b2dc;
    --tbluelight: #5ba1f727;
    --tbluebacklit: #4e9eff12;
    --tbluebacklitfocus: #4080cf21;
    --tgray: #E8E8E833;
    --telement: #4e9eff12;
    --telementhover: #4e9eff2b;
    --tsection: #232429f2;
    --tfocus: #355b89;
    --tgreen: #6bbd5ce7;
    --tyellow: #E5A50B;
    --tred: #CB4B4D;
    --tbuttonred: #CB4B4Dd6;

    body {
        margin: 0;
        background: url("./backgroundDark.jpeg") no-repeat fixed;
        height: 100vh;
        background-size: cover;
        transition: background 1s;
    }
}

html * {
    font-family: neo-sans, sans-serif;
    letter-spacing: 0.5px;
    caret-color: var(--tblue);
    /* Name - Weight Stylesˇ
        Light - 300 normal/italic
        Regular - 400 normal/italic
        Medium - 500 normal/italic
        Bold - 700 normal/italic
        Black - 900 normal/italic
    */
}

body {
    margin: 0;
    background: url("./background.png") no-repeat fixed;
    height: 100vh; 
    background-size: cover;
    transition: background 1s;
}

::-webkit-scrollbar {
    width: 9px;
}

::-webkit-scrollbar-track {
    border-radius: 50px;
}

::-webkit-scrollbar-thumb {
    background: var(--tbluebacklitfocus);
    border-radius: 50px;
    transition: background 0.4s;

    &:hover {
        background: var(--tbluelight);
    }
}

@mixin attachments {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 55px auto 55px;
    width: 500px;
    height: 800px;
    background-color: var(--telement);
    padding: 5px 30px 15px 30px;
    border-radius: 15px;

    &-title-container {
        display: flex;
        justify-self: center;
        align-items: center;
        height: 50px;
        gap: 18px;

        &-text {
            font-size: 22px;
            font-style: italic;
            font-weight: 500;
            color: var(--tbody);
        }
    }

    &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        gap: 10px;
        margin-left: 15px;
        overflow: hidden;

        &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: none;
            height: 130px;
            width: 230px;
            border: 2px solid var(--tgray);
            border-radius: 10px;
            user-select: none;
            cursor: pointer;
            opacity: 0.7;
            background-color: none;
            transition: opacity 0.4s ease, background-color 0.4s ease;

            &:hover {
                opacity: 1;
                background-color: var(--tbluebacklitfocus);
            }

            &_add {
                @extend .attachments-container-item;
                border: 3px solid var(--tblue);
                opacity: 0.67;
                background-color: none;
                transition: opacity 0.4s ease, background-color 0.4s ease;

                &:hover {
                    opacity: 1;
                    background-color: var(--tbluebacklit);
                }
            }

            &-icon {
                color: var(--tdropdown);
                font-size: 30px;
                opacity: 0.5;
                margin-top: 20px;
                margin-bottom: 20px;

                &_add {
                    font-size: 40px;
                    color: var(--tblue);
                }
            }

            &-text {
                font-size: 16px;
                color: var(--tbody);
            }
        }
    }

    &-paginator {
        display: flex;
        justify-self: center;
        align-self: end;
        gap: 14px;

        &-page {
            background: none;
            border: none;
            font-size: 24px;
            color: var(--tbluelight);
            font-weight: 600;
            cursor: pointer;
            user-select: none;
            transition: color 0.4s ease;
        }

        .active, &-page:focus, &-page:hover {
            color: var(--tblue);
        }

        .arrow {
            font-size: 18px;
            margin-top: 5px;
            color: var(--tbluelight);
            cursor: pointer;
            user-select: none;
            transition: color 0.3s ease;

            &:hover {
                color: var(--tblue);
            }
        }
    }
}

@mixin dropdown-type {
    position: relative;
    display: inline-block;

    &-btn {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        background-color: var(--tblue);
        color: var(--twhite);
        padding: 16px 22px;
        font-size: 16px;
        border-radius: 50px;
        border: none;
        cursor: pointer;
        width: 90px;
        transition: background-color .5s;

        &:hover, &:focus {
            background-color: var(--tfocus);
        }
    }

    &-icon {
        font-size: 23px;
        color: var(--twhite);
    }

    &-arrow {
        margin-top: 3px;
        opacity: 0.7;
    }

    &-menu {
        display: none;
        position: absolute;
        background-color: var(--tblue);
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        margin-top: 3px;
        padding: 2px;
        border-radius: 15px;

        &-item {
            font-size: 17px;
            padding: 12px 20px;
            margin: 5px 7px;
            text-decoration: none;
            border-radius: inherit;
            transition: color .2s, background-color .2s;

            &:hover {
                background-color: var(--tfocus);
            }
        }
    }

    .show {
        display: flex;
    }
}

@mixin dropdown-activity {

    &-btn {
        display: flex;
        gap: 12px;
        background-color: var(--tsection);
        color: var(--tdropdown);
        padding: 17px 20px;
        font-size: 16px;
        border: 2px solid var(--tsubtitle);
        border-radius: 50px;
        width: 190px;
    }

    &-circle {
        height: 25px;
        width: 25px;
        background-color: var(--tgreen);
        border-radius: 50%;
    }

    &-text {
        border-left: 2px solid var(--telement);
        padding-left: 12px;
        font-size: 18px;
    }
}

@mixin dropdown-availability {
    position: relative;
    display: inline-block;

    &-btn {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        background-color: var(--tsection);
        color: var(--tdropdown);
        padding: 17px 20px;
        font-size: 16px;
        border: 2px solid var(--tsubtitle);
        border-radius: 50px;
        cursor: pointer;
        width: 280px;
        transition: border .5s, color .5s;

        &:hover, &:focus {
            border: 2px solid var(--tblue);
            color: var(--tblue);
        }
    }

    &-circle {
        height: 25px;
        width: 25px;
        background-color: var(--tgreen);
        border-radius: 50%;
    }

    &-text {
        border-left: 2px solid var(--telement);
        padding-left: 10px;
        margin-right: 40px;
        margin-left: -24px;
        font-size: 18px;
    }

    &-menu {
        display: none;
        position: relative;
        flex-direction: column;
        background-color: var(--tsection);
        min-width: 200px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        margin-top: 5px;
        padding: 7px 0px;
        border-radius: 15px;

        &-item {
            color: var(--tbody);
            font-size: 17px;
            padding: 14px 16px;
            margin: 5px 7px;
            text-decoration: none;
            display: flex;
            border-radius: inherit;
            transition: color .3s, background-color .3s;

            &:hover {
                background-color: var(--tblue);
                color: var(--tsection);
            }
        }

        &-circle_green {
            height: 25px;
            width: 25px;
            background-color: var(--tgreen);
            border-radius: 50%;
            margin-right: 14px;
        }

        &-circle_red {
            height: 25px;
            width: 25px;
            background-color: var(--tred);
            border-radius: 50%;
            margin-right: 14px;
        }
    
        &-text {
            border-left: 2px solid var(--telement);
            padding-left: 10px;
            font-size: 18px;
        }
    }

    .show {
        display: block;
    }
}

@mixin login-form {
    display: flex;
    flex-direction: column;
    gap: 45px;

    &-input {
        display: flex;
        flex-direction: column;
        gap: 55px;
    }

    &-username {
        padding: 12px 16px;
        font-size: 20px;
        letter-spacing: 1.5px;
        background: none;
        color: var(--tfocus);
        caret-color: var(--tblue);
        border: none;
        border-bottom: 2px solid var(--tblue);
        outline: none;
        transition: background ease-in-out 0.3s;

        &::placeholder {
            font-style: italic;
            font-weight: 400;
            color: var(--tfocus);
            opacity: 0.5;
        }

        &:focus {
            border-bottom: 3px solid var(--tblue);
            background: var(--tbluebacklit);
            border-radius: 5px 5px 0px 0px;
        }
    }

    &-password {
        padding: 12px 16px;
        font-size: 20px;
        letter-spacing: 1.5px;
        background: none;
        color: var(--tfocus); 
        caret-color: var(--tblue);
        border: none;
        border-bottom: 2px solid var(--tblue);
        outline: none;
        transition: background ease-in-out 0.3s;

        &::placeholder {
            font-style: italic;
            font-weight: 400;
            color: var(--tfocus);
            opacity: 0.5;
        }

        &:focus {
            border-bottom: 3px solid var(--tblue);
            background: var(--tbluebacklit);
            border-radius: 5px 5px 0px 0px;
        }
    }
}

@mixin btn-disable {
    cursor: not-allowed;
    user-select: none;
    color: var(--twhite);
    background-color: var(--tbluelight);

    &:hover {
        transform: none;
        box-shadow: none;
    }

    &:active {
        transform: none;
        box-shadow: none;
    }
}